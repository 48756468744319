import styled from 'styled-components';
import {
  textColor,
  lightBorderColor,
  lightBackgroundColor,
  transitionTime,
  mediumBackgroundColor,
  darkBackgroundColor,
} from '../../../constants';

const buttonHeight = '35px';
const buttonWidthLarge = '250px';
const buttonWidthMedium = '145px';
const buttonWidthSmall = '100px';

export const ButtonBox = styled.div`
  position: relative;
  height: ${buttonHeight};
  &:hover {
    button,
    div {
      transform: translate3d(-1px, -1px, 0);
      opacity: 1;
    }
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
  }
  button {
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    height: ${buttonHeight};
    transition: all ${transitionTime} ease-in-out;
    border: 1px solid ${lightBorderColor};
    border-radius: 2px;
    z-index: 3;
    cursor: pointer;
    margin: 0;
    span {
      height: ${buttonHeight};
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: ${textColor};
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      padding: 0;
      line-height: 35px;
      text-transform: uppercase;
    }
    &.darkButton {
      border: 1px solid ${darkBackgroundColor};
      background: ${darkBackgroundColor};
      span {
        color: white;
      }
    }
    &.mediumColor {
      border: 1px solid ${mediumBackgroundColor};
      background: ${mediumBackgroundColor};
      span {
        color: ${textColor};
      }
    }
    &.grayButton {
      border: 1px solid ${mediumBackgroundColor};
      background: ${lightBackgroundColor};
      span {
        color: ${textColor};
      }
    }

    &:focus {
      outline: none;
    }
  }
  &.large {
    width: ${buttonWidthLarge};
    a {
      width: ${buttonWidthLarge};
    }
    button {
      width: ${buttonWidthLarge};
      span {
        width: ${buttonWidthLarge};
      }
    }
  }
  &.small {
    width: ${buttonWidthSmall};
    a {
      width: ${buttonWidthSmall};
    }
    button {
      width: ${buttonWidthSmall};
      span {
        width: ${buttonWidthSmall};
      }
    }
  }
  &.mediumSize {
    width: ${buttonWidthMedium};
    a {
      width: ${buttonWidthMedium};
    }
    button {
      width: ${buttonWidthMedium};
      span {
        width: ${buttonWidthMedium};
      }
    }
  }
`;

export const ButtonShadow = styled.div`
  height: ${buttonHeight};
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  transition: all ${transitionTime};
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.25);
  opacity: 0;
  &.large {
    width: ${buttonWidthLarge};
  }
  &.small {
    width: ${buttonWidthSmall};
  }
  &.mediumSize {
    width: ${buttonWidthMedium};
  }
`;

export const CloseButton = styled.button`
  border: 1px solid ${lightBorderColor};
  border-radius: 2px;
  z-index: 3;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  margin: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${buttonHeight};
  width: ${buttonHeight};
  background: ${lightBackgroundColor};
  right: 10px;
  top: 10px;
  color: ${textColor};
  transition: all ${transitionTime};
  opacity: 0.7;
  &:hover {
    opacity: 1.0;
  }
  div {
    font-size: 17px;
  }
`;
